import React from "react";
import { useState } from "react";
import gerebLogo from "../../img/home-img.png";
import deliveryMan from "../../img/deliveryman.webp";
import superman from "../../img/delivery-man-superman.webp";
import truck from "../../img/delivery-truck.jpg";
import "./hero.css";

export const Hero = () => {
  const [mobileNav, setMobileNav] = useState(false);

  const onMenuBtnClick = () => {
    if (window.innerWidth < 991) {
      setMobileNav(!mobileNav);
    }
  };
  return (
    <section id="hero">
      <div className="container">
        <br />
        <br />
        <br />
        <div className="page-wrapper">
          <div className="hero-content">
            <h1>
              Innovative Solutions <span className="rocket">🚀</span>
            </h1>
            <h2>Shaping the Future of Technology</h2>
            <p>
              If you are thinking of building software, an app, or any network
              installation, look no further than Gereb Technologies. We bring
              your vision to life.
            </p>
            <a
              onClick={() => {
                onMenuBtnClick();
              }}
              className="get-started"
              href="#contact"
            >
              Get Started
            </a>
            <br /> <br /> <br />
          </div>
        </div>

        <div className="product-highlight">
          <h3>Our Products</h3>
          <div className="product-content">
            <div className="product-text">
              <h4>Gereb Delivery</h4>
              <p>
                Revolutionizing food delivery for everyone! Our state-of-the-art
                platform connects you with the best restaurants in town. Get the
                food you want, where you want it, delivered by the fastest team
                in the business.
              </p>
              <ul>
                <li>Wide range of restaurant partners</li>
                <li>Lightning-fast delivery</li>
                <li>User-friendly mobile app</li>
                <li>Real-time order tracking</li>
              </ul>
              <a
                href="https://play.google.com/store/apps/details?id=com.gerebtechnologies.gerebuser&hl=en"
                className="btn-primary"
              >
                Try Gereb Delivery
              </a>
            </div>
            <div className="hero-images">
              <div className="image-grid">
                <img
                  src={deliveryMan}
                  alt="Delivery Person"
                  className="img-1"
                />
                <img
                  src={superman}
                  alt="Super Fast Delivery"
                  className="img-2"
                />
                <img src={truck} alt="Delivery Vehicle" className="img-3" />
              </div>
            </div>
          </div>
          <br />
          <div className="stats-section">
            <h3>Gereb Delivery overview</h3>
            <div className="stats-grid">
              <div className="stat-item">
                <div className="stat-number">25+</div>
                <div className="stat-label">Restaurant Partners</div>
                <div className="stat-date">as of January 2025</div>
              </div>
              <div className="stat-item">
                <div className="stat-number">1000+</div>
                <div className="stat-label">Monthly Orders</div>
                <div className="stat-date">as of January 2025</div>
              </div>
              <div className="stat-item">
                <div className="stat-number">2</div>
                <div className="stat-label">Cities Covered</div>
                <div className="stat-date">as of January 2025</div>
              </div>
              <div className="stat-item">
                <div className="stat-number">15+</div>
                <div className="stat-label">Delivery Drivers</div>
                <div className="stat-date">as of January 2025</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
