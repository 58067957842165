import React, { useEffect } from "react";
import "./portfolio.css";
import AOS from "aos";
import "aos/dist/aos.css";
import sfgLogo from "../../img/portfolio/SFG/Stem for girls.png";
import iibLogo from "../../img/portfolio/IIB/Logo-01.webp";
import yipLogo from "../../img/portfolio/YIP/YIP LOGO-01.png";
export const Portfolio = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    AOS.refresh();
  }, []);

  return (
    <section id="portfolio" className="portfolio section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Our Projects</h2>
          <p>
          Our projects are diverse and forward-thinking, aiming to make a meaningful impact in various domains. 
          Through our innovative endeavors, we strive to develop solutions that enhance efficiency, streamline processes, 
          and improve user experiences.
          </p>
        </div>

        <div
          className="portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="portfolio-content d-flex reverse">
            <div className="img">
              <img src={sfgLogo} draggable="false" alt="" />
            </div>
            <div className="portfolio-details d-flex">
              <div className="contentBlock">
                <h4>Gereb Delivery User App</h4>
                <p>
                Gereb Delivery User App is a convenient mobile application that allows users to order food and medicines from anywhere. With its user-friendly interface, 
                users can browse through a wide range of options and place their orders seamlessly. The app features a robust tracking system that enables users to track their orders in real-time, 
                ensuring transparency and peace of mind. Additionally, the app offers flexible payment options, 
                allowing users to choose between cash on delivery or utilizing the integrated payment system for a hassle-free transaction
                </p>
              </div>
            </div>
          </div>
          <div className="portfolio-content d-flex">
            <div className="img">
              <img src={iibLogo} draggable="false" alt="" />
            </div>
            <div className="portfolio-details d-flex">
              <div className="contentBlock">
                <h4>Gereb Admin Dashboard</h4>
                <p>
                Gereb Admin Dashboard is a versatile tool for food and medicine delivery companies. It enables administrators to create/delete stores, manage inventory, accept and track orders, and assign delivery personnel. 
                With its user-friendly interface, it streamlines operations and empowers efficient management of the business.
                </p>
                
              </div>
            </div>
          </div>
          <div className="portfolio-content d-flex yip reverse">
            <div className="img">
              <img src={yipLogo} draggable="false" alt="" />
            </div>
            <div className="portfolio-details d-flex">
              <div className="contentBlock">
                <h4>Store Dashboard</h4>
                <p>
                The Store Dashboard for Gereb is a streamlined tool that empowers store owners to efficiently manage their operations. 
                It allows easy registration and provides a seamless interface for accepting and canceling orders,
                 ensuring effective order fulfillment and coordination with delivery personnel. Simplifying the process,
                 the dashboard enables store owners to focus on delivering excellent service to their customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
